import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In my last year of college, and while working at my `}<a parentName="p" {...{
        "href": "/ctg"
      }}>{`internship`}</a>{`, a friend
from queens reached out to me about building a mobile app. The idea was to
build an app that would allow club or bar goers to send song requests to the
DJ. After a song request was sent, other attendees would also be able to view
the different requests through the app and vote on which they liked most.`}</p>
    <p>{`I talked to one of the backend developers at my internship and we decided that
we liked the idea enough to collaborate on it. After some discussion, we landed
on `}<a parentName="p" {...{
        "href": "https://phonegap.com/"
      }}>{`phonegap`}</a>{` (a wrapper around `}<a parentName="p" {...{
        "href": "https://cordova.apache.org/"
      }}>{`cordova`}</a>{`) for the implementation as it
would allow us to build it for both Android and iOS using the web technologies
we were already familiar with.`}</p>
    <p>{`So having no idea what we were really getting ourselves into, we had a few
screenshares and I started some sketches and then turned those into
higher-resolution comps like this splash page...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4b6da71ad37ec18450b36f4456b21b40/644c5/request-splash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHDYqSoB//EABgQAAIDAAAAAAAAAAAAAAAAAAABAiAh/9oACAEBAAEFAkOOU//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABIDH/2gAIAQEABj8CNU//xAAYEAEAAwEAAAAAAAAAAAAAAAABABARMf/aAAgBAQABPyE69yAaoItf/9oADAMBAAIAAwAAABDzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQEAAwEAAAAAAAAAAAAAAAEAETFhsf/aAAgBAQABPxASEdMyDcFz5O54kSd3/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "request splash page",
            "title": "request splash page",
            "src": "/static/4b6da71ad37ec18450b36f4456b21b40/644c5/request-splash.jpg",
            "srcSet": ["/static/4b6da71ad37ec18450b36f4456b21b40/158ba/request-splash.jpg 360w", "/static/4b6da71ad37ec18450b36f4456b21b40/80e3c/request-splash.jpg 720w", "/static/4b6da71ad37ec18450b36f4456b21b40/644c5/request-splash.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once we felt like the designs were in a good place, we started diving more into
the actual implementation. Being somewhat new to web development -- and very
new to the CLI and mobile app development -- this definitely became a wormhole
for my first summer out of college. There's something to be said for learning
things the hard way, and I definitely did a lot of that over that summer.`}</p>
    <p>{`From untangling `}<inlineCode parentName="p">{`phonegap`}</inlineCode>{`, a freemium adobe wrapper around `}<inlineCode parentName="p">{`cordova`}</inlineCode>{`, to
using xcode and deploying to both app stores; this project quickly became a
small nightmare. On top of all that, we had to build the app itself and the
other developer had just started an internship so we were both somewhat
strapped for time.`}</p>
    <p>{`Against the odds, we still managed to get apps working and installed on both
iOS and Android devices. Here's some screenshots of what the end product looked
like live:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b9cf161dfcac4570eb88f7ae94a4b51b/644c5/request-screens.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGe1JwuP//EABkQAQEAAwEAAAAAAAAAAAAAAAESABExQ//aAAgBAQABBQIHNMtWlvk9/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgAHAQAAAAAAAAAAAAAAAAECESIyQUJREv/aAAgBAQAGPwJVLbJcrFsRVZ6QtvqPM9Rn/8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAoSExUZH/2gAIAQEAAT8hXvPJkeg7A0YiwJgVRLIQE3CKnoqf/9oADAMBAAIAAwAAABAv/wD/xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EJxT/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCNW//EABwQAQEAAwADAQAAAAAAAAAAAAERACFBMWFx8f/aAAgBAQABPxApKk+bHlkfMJuZ+4aBloa8PWLMapDRJzFqYWYWo7MOfWTQ8PWf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "request screens",
            "title": "request screens",
            "src": "/static/b9cf161dfcac4570eb88f7ae94a4b51b/644c5/request-screens.jpg",
            "srcSet": ["/static/b9cf161dfcac4570eb88f7ae94a4b51b/158ba/request-screens.jpg 360w", "/static/b9cf161dfcac4570eb88f7ae94a4b51b/80e3c/request-screens.jpg 720w", "/static/b9cf161dfcac4570eb88f7ae94a4b51b/644c5/request-screens.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Unfortunately, the two DJs who originally had the idea never really came
through on the marketing end and the project hit a dead-end. I mistakenly let
the project come between myself and the other developer and we ended up both
going our own ways into our first actual jobs. Nonetheless, I learned a ton
from this project and will always look back on it as one of my first real
software endeavors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      